import React from "react";
import { SideNav } from "../../components";

const Social = () => {
  return (
    <div className="activity-container">
      <div
        className="activity-hero-container"
        style={{ backgroundImage: "url(/assets/images/social.jpeg)" }}
      >
        <div className="b-container">
          <div className="activity-hero">
            <h2>Social</h2>
          </div>
        </div>
      </div>
      <div className="b-container">
        {" "}
        <div className="activity-main-container">
          <SideNav />

          <div className="activity-content-container">
            <div>
              Notre ONG s'engage à créer un impact positif dans la société à
              travers diverses initiatives sociales. Nous croyons fermement en
              l'importance de soutenir les communautés les plus vulnérables et
              marginalisées. Voici quelques-unes des activités que nous menons
              dans le domaine social
            </div>
            <div>
              <h6>Programmes d'aide alimentaire</h6>
              <p>
                Nous distribuons des repas nutritifs et des provisions
                essentielles aux familles et aux individus dans le besoin,
                contribuant ainsi à lutter contre la faim et la malnutrition.
              </p>
            </div>
            <div>
              <h6>Soutien à l'éducation</h6>
              <p>
                Nous offrons des bourses d'études, des fournitures scolaires et
                des programmes éducatifs pour permettre aux enfants défavorisés
                d'accéder à une éducation de qualité et à des opportunités
                d'avenir prometteuses.
              </p>
            </div>
            <div>
              <h6>Assistance aux personnes âgées</h6>
              <p>
                Nous organisons des activités de soutien et de compagnie pour
                les personnes âgées, visant à prévenir l'isolement social et à
                promouvoir leur bien-être mental et émotionnel.
              </p>
            </div>
            <div>
              <h6>Sensibilisation aux droits de l'homme</h6>
              <p>
                Nous menons des campagnes de sensibilisation et des formations
                sur les droits de l'homme, en mettant l'accent sur l'égalité des
                genres, la justice sociale et la lutte contre la discrimination.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Social;

import React from "react";
import { SideNav } from "../../components";

const Youth = () => {
  return (
    <div className="activity-container">
      <div
        className="activity-hero-container"
        style={{ backgroundImage: "url(/assets/images/youth.jpeg)" }}
      >
        <div className="b-container">
          <div className="activity-hero">
            <h2>Autonomisation des jeunes et des femmes</h2>
          </div>
        </div>
      </div>
      <div className="b-container">
        {" "}
        <div className="activity-main-container">
          <SideNav />

          <div className="activity-content-container">
            <div>
              L'autonomisation des jeunes et des femmes est au cœur de notre
              vision pour un avenir équitable et inclusif. Nous sommes
              déterminés à renforcer les capacités et à créer des opportunités
              pour ces groupes marginalisés. Voici quelques-unes de nos
              initiatives en matière d'autonomisation des jeunes et des femmes
            </div>
            <div>
              <h6>Programmes de leadership et de développement personnel</h6>
              <p>
                Nous proposons des formations en leadership, en entrepreneuriat
                et en compétences professionnelles pour permettre aux jeunes et
                aux femmes de réaliser leur plein potentiel et de devenir des
                agents de changement dans leur communauté.
              </p>
            </div>
            <div>
              <h6>Accès à l'éducation et à la formation</h6>
              <p>
                Nous soutenons l'accès à l'éducation formelle et non formelle
                pour les jeunes filles et les femmes, en mettant en place des
                bourses d'études, des programmes de mentorat et des ateliers de
                renforcement des compétences.
              </p>
            </div>
            <div>
              <h6>Initiatives économiques</h6>
              <p>
                Nous encourageons l'entrepreneuriat féminin et la création de
                petites entreprises par le biais de formations, de financements
                et de réseautage, visant à accroître l'indépendance économique
                des femmes et à réduire les inégalités de genre.
              </p>
            </div>
            <div>
              <h6>Promotion de l'égalité des genres</h6>
              <p>
                Nous menons des campagnes de sensibilisation et des actions de
                plaidoyer pour promouvoir l'égalité des genres, lutter contre
                les discriminations et les violences basées sur le genre, et
                garantir la participation équitable des femmes dans tous les
                domaines de la société.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Youth;

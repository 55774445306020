import axios from "axios";
class AuthService {
  login(data) {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, data);
  }

  logout() {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/auth/logout`)
      .then((res) => {
        console.log("I'm in")
        sessionStorage.clear();
        window.location.reload();
      });
  }
}

export default new AuthService();

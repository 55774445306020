import React, { useLayoutEffect } from 'react';
import {useNavigate} from "react-router-dom"
import { routes } from '../../routes';

const Activity = () => {
  const navigate = useNavigate()
  useLayoutEffect(() => {
   navigate(routes.tic)
  }, [])
  return (
    <div>
      
    </div>
  );
};

export default Activity;
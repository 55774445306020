import React from "react";
import { Link, NavLink } from "react-router-dom";
import { routes } from "../../../routes";
import { FaChevronDown } from "react-icons/fa";

const DefaultHeader = () => {
  return (
    <div className="default-header-container">
      <div className="b-container">
        <div className="default-header-wraper">
          <Link to={routes.home}>
            <div className="default-header-logo">
              <img src="/assets/images/logo.png" alt="logo" />
            </div>
          </Link>
          <div className="default-header">
            <ul>
              <NavLink
                to={routes.home}
                className={(nav) => (nav.isActive ? "nav-active" : "")}
              >
                <li>Accueil</li>
              </NavLink>

              <NavLink
                to={routes.about}
                className={(nav) => (nav.isActive ? "nav-active" : "")}
              >
                <li>A propos</li>
              </NavLink>
              <NavLink
                to={routes.contact}
                className={(nav) => (nav.isActive ? "nav-active" : "")}
              >
                <li>Nous contacter</li>
              </NavLink>
              {/* <NavLink
                to={routes.activities}
                className={(nav) => (nav.isActive ? "nav-active" : "")}
              > */}
                <div className="dropdown-container">
                  <div className="dropdown-title">
                    Nos activités{" "}
                    <span className="dropdown-icon">
                      <FaChevronDown />
                    </span>
                  </div>
                  <div className="dropdown-content">
                    <ul>
                      <Link to={routes.tic}>
                        <li>TIC</li>
                      </Link>
                      <Link to={routes.social}>
                        <li>Social</li>
                      </Link>
                      <Link to={routes.environment}>
                        <li>Environnement</li>
                      </Link>
                      <Link to={routes.youth}>
                        <li>Autonomisation</li>
                      </Link>
                    </ul>
                  </div>
                </div>
             {/*  </NavLink> */}
            </ul>
            {/* 
            <NavLink to={routes.postCommand}>
              <div className="get-started-btn">Visiter notre Blog</div>
            </NavLink> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultHeader;

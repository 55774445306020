import React from "react";
import { SideNav } from "../../components";

const Environment = () => {
  return (
    <div className="activity-container">
      <div
        className="activity-hero-container"
        style={{ backgroundImage: "url(/assets/images/environment.jpeg)" }}
      >
        <div className="b-container">
          <div className="activity-hero">
            <h2>Environnement</h2>
          </div>
        </div>
      </div>
      <div className="b-container">
        {" "}
        <div className="activity-main-container">
          <SideNav />

          <div className="activity-content-container">
            <div>
              Notre engagement envers la protection de l'environnement est au
              cœur de notre mission. Nous travaillons activement pour préserver
              les ressources naturelles, promouvoir la durabilité et
              sensibiliser à l'importance de la conservation. Voici un aperçu de
              nos actions dans le domaine de l'environnement
            </div>
            <div>
              <h6>Campagnes de reboisement</h6>
              <p>
                Nous organisons des initiatives de plantation d'arbres pour
                restaurer les écosystèmes dégradés et lutter contre la
                déforestation, contribuant ainsi à atténuer les effets du
                changement climatique.
              </p>
            </div>
            <div>
              <h6>Nettoyage des déchets</h6>
              <p>
                Nous mobilisons des volontaires pour des opérations de nettoyage
                des plages, des parcs et des zones publiques, visant à réduire
                la pollution plastique et à préserver la biodiversité marine et
                terrestre.
              </p>
            </div>
            <div>
              <h6>Éducation environnementale</h6>
              <p>
                Nous proposons des ateliers et des programmes éducatifs sur les
                enjeux environnementaux, afin de sensibiliser les communautés
                locales aux pratiques durables et à la gestion responsable des
                ressources naturelles.
              </p>
            </div>
            <div>
              <h6>Promotion des énergies renouvelables</h6>
              <p>
                Nous encourageons l'adoption des énergies propres et
                renouvelables, en mettant en place des projets pilotes et des
                initiatives de sensibilisation sur l'importance de la transition
                énergétique.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Environment;

import React from "react";
import MySpinner from "../spinner/MySpinner";

const SubmitBtn = ({ isLoading, title, color }) => {
  return (
    <div className="submit-btn">
      <button
        type="submit"
        disabled={isLoading}
        style={color && { backgroundColor: color }}
      >
        <span>{title}</span>
        <MySpinner active={isLoading} />
        <MySpinner active={isLoading} />
        <MySpinner active={isLoading} />
      </button>
    </div>
  );
};

export default SubmitBtn;

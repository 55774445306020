import React from "react";

const Privacy = () => {
  return (
    <div className="privacy-container">
      <div className="privacy-hero-container">
        <div className="b-container">
          <div className="privacy-hero">
            <h2>Politiques de Confidentialités</h2>
          </div>
        </div>
      </div>
      <div className="privacy-term-container">
        <div className="b-container">
          <div className="privacy-term-wraper">
            <div className="privacy-term-title">
              Politique de confidentialité
            </div>
            <div className="privacy-term-desc">
              La présente politique de confidentialité régit la manière dont
              l'ONG Happy Life and Peace collecte, utilise, protège et divulgue
              les informations personnelles des utilisateurs ("vous" ou
              "utilisateurs") lors de l'accès et de l'utilisation de notre site
              web. Nous accordons une grande importance à la protection de votre
              vie privée et nous nous engageons à respecter les normes les plus
              strictes en matière de confidentialité des données.
            </div>
          </div>
        </div>
      </div>
      <div className="privacy-term-container">
        <div className="b-container">
          <div className="privacy-term-wraper">
            <div className="privacy-term-title">
              Collecte d'informations personnelles
            </div>
            <div className="privacy-term-desc">
              Lorsque vous utilisez notre site web, il se peut que nous vous
              demandions de nous fournir certaines informations personnelles
              identifiables, telles que votre nom, votre adresse électronique,
              votre numéro de téléphone, afin de vous fournir des informations
              pertinentes et de répondre à vos demandes. Nous collectons ces
              informations de manière légale et éthique, et nous ne les
              utiliserons que dans le cadre des objectifs définis dans cette
              politique de confidentialité.
            </div>
          </div>
        </div>
      </div>
      <div className="privacy-term-container">
        <div className="b-container">
          <div className="privacy-term-wraper">
            <div className="privacy-term-title">
              Utilisation des informations
            </div>
            <div className="privacy-term-desc">
              Les informations personnelles que nous collectons peuvent être
              utilisées pour :
              <ol>
                <li>
                  {" "}
                  Vous fournir des informations, des actualités et des mises à
                  jour sur nos activités, nos projets et nos initiatives.
                </li>
                <li>Répondre à vos demandes, questions ou préoccupations.</li>
                <li>
                  Améliorer et personnaliser votre expérience sur notre site
                  web.
                </li>
                <li>
                  Analyser et comprendre l'utilisation de notre site web afin
                  d'améliorer son fonctionnement et son contenu.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="privacy-term-container">
        <div className="b-container">
          <div className="privacy-term-wraper">
            <div className="privacy-term-title">
              Protection des informations
            </div>
            <div className="privacy-term-desc">
              Nous nous engageons à prendre toutes les mesures nécessaires pour
              protéger vos informations personnelles contre tout accès non
              autorisé, toute divulgation ou toute altération. Nous mettons en
              place des mesures de sécurité appropriées pour prévenir la perte,
              l'utilisation abusive ou la modification des informations que vous
              nous avez fournies.
            </div>
          </div>
        </div>
      </div>
      <div className="privacy-term-container">
        <div className="b-container">
          <div className="privacy-term-wraper">
            <div className="privacy-term-title">
              Divulgation des informations
            </div>
            <div className="privacy-term-desc">
              <p>
                Nous ne vendons, ne louons ni ne partageons vos informations
                personnelles avec des tiers, sauf dans les cas suivants :
              </p>
              <ol>
                <li>
                  Lorsque cela est requis par la loi ou les autorités
                  compétentes pour se conformer à une obligation légale ou pour
                  protéger nos droits légaux.
                </li>
                <li>
                  Lorsque cela est nécessaire pour exécuter nos services et
                  activités, en impliquant des sous-traitants ou des partenaires
                  tiers qui sont soumis à des obligations de confidentialité.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="privacy-term-container">
        <div className="b-container">
          <div className="privacy-term-wraper">
            <div className="privacy-term-title">Liens externes</div>
            <div className="privacy-term-desc">
              Notre site web peut contenir des liens vers des sites web
              externes. Veuillez noter que nous n'avons aucun contrôle sur le
              contenu et les pratiques de confidentialité de ces sites. Nous
              vous encourageons à consulter les politiques de confidentialité de
              ces sites avant de leur fournir des informations personnelles.
            </div>
          </div>
        </div>
      </div>
      <div className="privacy-term-container">
        <div className="b-container">
          <div className="privacy-term-wraper">
            <div className="privacy-term-title">Conservation des données</div>
            <div className="privacy-term-desc">
              Nous conserverons vos informations personnelles aussi longtemps
              que nécessaire pour atteindre les objectifs énoncés dans cette
              politique de confidentialité, sauf si la loi exige ou autorise une
              période de conservation plus longue.
            </div>
          </div>
        </div>
      </div>
      <div className="privacy-term-container">
        <div className="b-container">
          <div className="privacy-term-wraper">
            <div className="privacy-term-title">
              Modifications de la politique de confidentialité
            </div>
            <div className="privacy-term-desc">
              Nous nous réservons le droit de mettre à jour ou de modifier
              occasionnellement cette politique de confidentialité pour refléter
              les changements dans nos pratiques ou pour se conformer aux
              exigences légales. Nous vous encourageons à consulter
              régulièrement cette page pour prendre connaissance des éventuelles
              modifications.
            </div>
          </div>
        </div>
      </div>
      <div className="privacy-term-container">
        <div className="b-container">
          <div className="privacy-term-wraper">
            <div className="privacy-term-title">Contactez-nous</div>
            <div className="privacy-term-desc">
              Si vous avez des questions, des préoccupations ou des demandes
              concernant cette politique de confidentialité ou la manière dont
              nous traitons vos informations personnelles, veuillez nous
              contacter à l'adresse suivante : contact@hlp.org
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;

import React, { useEffect, useRef } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link, NavLink, useLocation } from "react-router-dom";
import { routes } from "../../../routes";

const ResponsiveHeader = () => {
  const { pathname } = useLocation();

  return (
    <div className="responsive-header-container">
      <div className="responsive-header-wraper">
        <Link to={routes.home}>
          <div className="responsive-header-logo">
            <img src="/assets/images/logo-neutre.png" alt="logo" />
          </div>
        </Link>
        <div className="responsive-header">
          <Menu
            pageWrapId={"page-wrap"}
            outerContainerId={"outer-container"}
            right
          >
            <div className="responsive-header">
              <ul>
                <a
                  id="home"
                  href={routes.home}
                  className={
                    pathname == routes.home
                      ? "nav-active menu-item"
                      : "menu-item"
                  }
                >
                  <li>Accueil</li>
                </a>

                <a
                  id="about"
                  href={routes.about}
                  className={
                    pathname == routes.about
                      ? "nav-active menu-item"
                      : "menu-item"
                  }
                >
                  <li>A propos</li>
                </a>
                <a
                  href={routes.contact}
                  id="contact"
                  className={
                    pathname == routes.contact
                      ? "nav-active menu-item"
                      : "menu-item"
                  }
                >
                  <li>Nous contacter</li>
                </a>
                <a
                  href={routes.tic}
                  id="activity"
                  className={
                    pathname == routes.tic
                      ? "nav-active menu-item"
                      : "menu-item"
                  }
                >
                  <li>Nos activités</li>
                </a>
              </ul>
              {/* 
                <NavLink to={routes.postCommand}>
                <div className="get-started-btn">Visiter notre Blog</div>
                </NavLink> */}
            </div>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveHeader;

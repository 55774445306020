import { Carousel } from "react-bootstrap";
import React from "react";
import {
  FaDesktop,
  FaFolder,
  FaFolderOpen,
  FaLaptop,
  FaLightbulb,
  FaPeopleArrows,
  FaPeopleCarry,
  FaSeedling,
  FaUser,
  FaUsers,
} from "react-icons/fa";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { routes } from "../../routes";
import Slider from "react-slick";
import { galleryData } from "../../utils/data";

const Home = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    lazyLoad: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 15500,
    cssEase: "linear",
    slidesPerRow: 4,
    pauseOnHover: true,
   // slidesToShow: 1,
    rows: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesPerRow: 1,
          rows: 1,
        },
      },
    ],
  };

  return (
    <div className="home-container">
      <div className="home-hero-container">
        <Carousel fade /* slide */ interval={5000}>
          <Carousel.Item>
            <div
              className="home-hero-wraper"
              style={{ backgroundImage: "url(/assets/images/social.jpeg)" }}
            >
              <div className="b-container">
                <div className="home-hero">
                  <h2>
                    La diversité est notre force, et l'inclusion est notre choix
                  </h2>
                  <p>
                    Nous sommes tous des membres d'une communauté mondiale, nous
                    devons donc tous contribuer à sa croissance et à son
                    bien-être. Chaque année nous portons assistance aux enfants
                    orphelins et aux personnes en situation d'handicap à travers
                    des dons.
                  </p>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="home-hero-wraper"
              style={{
                backgroundImage: "url(/assets/images/environment.jpeg)",
              }}
            >
              <div className="b-container">
                <div className="home-hero">
                  <h2>
                    Protéger l'environnement, c'est protéger notre avenir commun
                  </h2>
                  <p>
                    La nature pleure. Il est de notre responsabilité de la
                    sauver. Chaque action compte pour un environnement plus sain
                    et plus durable. Ensemble, nous pouvons créer un monde plus
                    vert et plus prospère. Nous avons la responsabilité de
                    protéger l'environnement pour les générations futures.
                    Ensemble bougeons et sauvons la planète.
                  </p>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="home-hero-wraper"
              style={{ backgroundImage: "url(/assets/images/technology.jpg)" }}
            >
              <div className="b-container">
                <div className="home-hero">
                  <h2>La technologie pour un monde plus connecté</h2>
                  <p>
                    Les TIC peuvent transformer les vies et les communautés.
                    Chaque année à travers notre projet de formation gratuite en
                    informatique de base nous formons les jeunes des villes et
                    contrées reculées de notre pays.
                  </p>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="home-hero-wraper"
              style={{ backgroundImage: "url(/assets/images/youth.jpeg)" }}
            >
              <div className="b-container">
                <div className="home-hero">
                  <h2>
                    Investir dans les jeunes et les femmes, c'est investir dans
                    l'avenir
                  </h2>
                  <p>
                    Les jeunes et les femmes ont le pouvoir de changer le monde,
                    donnons-leur les outils pour réussir. L'autonomisation des
                    jeunes est essentielle pour bâtir des communautés fortes et
                    résilientes. Nous contribuons à l'autonomisation des jeunes
                    et des femmes à travers des ateliers de formation en
                    sérigraphie et chantournage
                  </p>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="about-section-container">
        <div className="b-container">
          <div className="about-section">
            <div className="about-section-content">
              <h2>A propos de nous</h2>
              <p>
                Happy Life and Peace est une organisation est une organisation
                non gouvernementales dont le siège est situé au Bénin. Nous
                sommes une association de jeunes bénévoles qui travaillent
                activment pour le mieux être des communautés à la base, surtout
                dans les zones réculées du Bénin. Notre vision est de contribuer
                à un monde plus juste et plus durable en travaillant avec les
                communautés locales, les partenaires gouvernementaux, les
                organisations non gouvernementales et les entreprises pour créer
                des changements significatifs et durables dans les domaines qui
                comptent le plus. Être une institution de référence pour la
                promotion des des TIC, le social, l'autonomisation des jeunes et
                des femmes des jeune et de la femme puis la protection de
                l'environnement
              </p>
              <Link to={routes.about}>
                <div className="d-flex">
                  <div className="get-started-btn">En savoir plus sur nous</div>
                </div>
              </Link>
            </div>
            <div className="about-section-img">
              <img src="./assets/images/aboutH.jpg" alt="" />
              <div className="about-section-another">
                <img src="./assets/images/aboutHp.jpeg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="statistic-section-container">
        <div className="b-container">
          <div className="statistic-section">
            <div className="statistic">
              <FaPeopleCarry />

              <h5>
                +<CountUp end={1000} enableScrollSpy delay={3} />
              </h5>
              <h6>Bénéficiares </h6>
            </div>
            <div className="statistic">
              <FaFolderOpen />
              <h5>
                <CountUp end={4} enableScrollSpy />
              </h5>
              <h6>Domaines d'intervention</h6>
            </div>
            <div className="statistic">
              <FaLightbulb />
              <h5>
                +<CountUp end={25} enableScrollSpy />
              </h5>

              <h6>Projets pillotés</h6>
            </div>
            <div className="statistic">
              <FaUsers />
              <h5>
                +<CountUp enableScrollSpy end={100} />
              </h5>
              <h6>Volontaires</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="fields-section-container">
        <div className="b-container">
          <h2>Nos domaines d'intervention</h2>
          <div className="fields-section">
            <div className="fields">
              <div className="fields-icon">
                <FaLaptop />
                <h6>TIC</h6>
              </div>
              <div className="fields-content">
                L'autonomisation numérique est essentielle pour l'accès à
                l'information et à l'éducation en brisant les barrières sociales
                et géographique
              </div>
            </div>
            <div className="fields">
              <div className="fields-icon">
                <FaPeopleCarry />
                <h6>Social</h6>
              </div>
              <div className="fields-content">
                Chacun de nous peut faire une différence dans la vie d'une
                personne, ensemble nous pouvons changer le monde.
              </div>
            </div>
            <div className="fields">
              <div className="fields-icon">
                <FaUser />
                <h6>Autonomisation des jeunes et des femmes</h6>
              </div>
              <div className="fields-content">
                L'autonomisation des jeunes et des femmes est primordiale pour
                briser les cycles d'injustices sociales et la pauvreté à la
                base.
              </div>
            </div>
            <div className="fields">
              <div className="fields-icon">
                <FaSeedling />
                <h6>Environnement</h6>
              </div>
              <div className="fields-content">
                La protection de l'environnement est un choix consient que nous
                devrons faire dès maintenant pour protéger et sauver notre
                planète.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="partner-section-container">
        <div className="b-container">
          <div className="partner-section">
            <div className="partner">
              <img
                src="/assets/images/pds-neutre.png"
                alt="association_parlons_droit_et_societe"
              />
            </div>
            <div className="partner">
              <img
                src="/assets/images/aa-consulting-neutre.png"
                alt="aa_consulting"
              />
            </div>
            <div className="partner">
              <img
                src="/assets/images/prosperity-neutre.png"
                alt="ong_peace_and_prosperity"
              />
            </div>
            <div className="partner">
              <img src="/assets/images/jci-neutre.png" alt="jci" />
            </div>
            <div className="partner">
              <img
                src="/assets/images/pds-neutre.png"
                alt="association_parlons_droit_et_societe"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="gallery-section-container">
        <div className="b-container">
          <div className="gallery-section-wraper">
            <h2>Galerie d'images</h2>
            <p>Découvrez nos superbes collections d'images de nos activités</p>
            <div className="gallery-section">
              <div className="slider-container">
                <Slider {...settings}>
                  {galleryData.slice(0, 20).map((imageSrc, index) => (
                    <Link key={index} to={imageSrc} target="_blank" className="slide-image">
                      <img src={imageSrc} alt="" />
                    </Link>
                  ))}
                </Slider>
              </div>
              <div className="gallery-cta">
                <Link to={routes.gallery}>
                  <div className="d-flex">
                    <div className="get-started-btn">
                      Consultez l'intégralité de notre gallerie
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fields-section-container">
        <div className="b-container">
          <h2>Quelques activités à venir</h2>
          <div className="fields-section">
            <div className="fields">
              <div className="fields-icon">
                <FaLaptop />
                <h6>PZA3M</h6>
              </div>
              <div className="fields-content">
                L'autonomisation numérique est essentielle pour l'accès à
                l'information et à l'éducation en brisant les barrières sociales
                et géographique
              </div>
            </div>
            <div className="fields">
              <div className="fields-icon">
                <FaPeopleCarry />
                <h6>Social</h6>
              </div>
              <div className="fields-content">
                Chacun de nous peut faire une différence dans la vie d'une
                personne, ensemble nous pouvons changer le monde.
              </div>
            </div>
            <div className="fields">
              <div className="fields-icon">
                <FaUser />
                <h6>Autonomisation des jeunes et des femmes</h6>
              </div>
              <div className="fields-content">
                L'autonomisation des jeunes et des femmes est primordiale pour
                briser les cycles d'injustices sociales et la pauvreté à la
                base.
              </div>
            </div>
            <div className="fields">
              <div className="fields-icon">
                <FaSeedling />
                <h6>Environnement</h6>
              </div>
              <div className="fields-content">
                La protection de l'environnement est un choix consient que nous
                devrons faire dès maintenant pour protéger et sauver notre
                planète.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { routes } from "../../routes";
import { SideNav } from "../../components";

const Tic = () => {
  return (
    <div className="activity-container">
      <div
        className="activity-hero-container"
        style={{ backgroundImage: "url(/assets/images/technology.jpeg)" }}
      >
        <div className="b-container">
          <div className="activity-hero">
            <h2>TIC</h2>
          </div>
        </div>
      </div>
      <div className="b-container">
        {" "}
        <div className="activity-main-container">
          <SideNav />

          <div className="activity-content-container">
            <div>
              Notre ONG reconnaît le pouvoir des technologies de l'information
              et de la communication pour transformer les sociétés et améliorer
              les conditions de vie des individus. Nous œuvrons pour la
              promotion de l'accès aux TIC et pour l'autonomisation numérique
              des communautés. Voici quelques-unes de nos initiatives dans le
              domaine des TIC
            </div>
            <div>
              <h6>Accès à Internet</h6>
              <p>
                Nous facilitons l'accès à Internet dans les zones rurales et
                défavorisées, en fournissant des infrastructures de connectivité
                et en organisant des formations sur l'utilisation d'Internet et
                des outils numériques.
              </p>
            </div>
            <div>
              <h6>Formation aux compétences numériques</h6>
              <p>
                Nous proposons des programmes de formation en informatique et en
                technologie, afin de renforcer les compétences numériques des
                jeunes et des adultes et de favoriser leur employabilité dans
                l'économie numérique.
              </p>
            </div>
            <div>
              <h6>Promotion de l'innovation technologique</h6>
              <p>
                Nous soutenons les initiatives entrepreneuriales et les projets
                innovants dans le domaine des TIC, en offrant un accompagnement
                et un soutien financier aux startups et aux jeunes innovateurs.
              </p>
            </div>
            <div>
              <h6>Utilisation des TIC pour le développement durable</h6>
              <p>
                Nous explorons les applications des TIC dans des domaines tels
                que la santé, l'éducation, l'agriculture et la gouvernance, afin
                de stimuler le développement durable et l'inclusion sociale.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tic;

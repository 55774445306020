import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../../routes";
import { FaFacebook, FaLinkedin, FaTwitter, FaWhatsapp } from "react-icons/fa";

const DefaultFooter = () => {
  return (
    <div className="default-footer-container">
      <div className="b-container">
        <div className="footer-wraper">
          <div className="footer-container">
            <div className="footer-title">
              <img src="/assets/images/logo.png" alt="logo" />
            </div>
            <div className="footer-ctn">
              <div>
                Parce que pour nous le bonheur est synonyme de partage,
                d'émotion et surtout de joie.
              </div>
            </div>
          </div>
          <div className="footer-container">
            <div className="footer-title">Liens Utiles</div>
            <div className="footer-ctn">
              <ul>
                <Link to={routes.home}>
                  <li> Accueil</li>
                </Link>
                <Link to={routes.about}>
                  <li> A propos</li>
                </Link>
                <Link to={routes.contact}>
                  <li>Nous contacter</li>
                </Link>
                <Link to={routes.activities}>
                  <li> Activités </li>
                </Link>
                {/* <Link to={routes.report}>
                  <li> Rapports d'activité </li>
                </Link> */}
              </ul>
            </div>
          </div>
          <div className="footer-container">
            <div className="footer-title">Plus</div>
            <div className="footer-ctn">
              <ul>
                <Link to={routes.gallery}>
                  <li>Notre gallerie</li>
                </Link>
                <Link to={routes.team}>
                  <li>Notre équipe</li>
                </Link>
                <Link to={routes.privacy}>
                  <li> Politiques de Confidentialités</li>
                </Link>
                <Link to={routes.terms}>
                  <li>Termes et Conditions Générales d'Utilisations</li>
                </Link>
              </ul>
            </div>
          </div>
          <div className="footer-container">
            <div className="footer-title">Nos réseaux</div>
            <div className="footer-ctn">
              <ul>
                <Link to={routes.home}>
                  <li>
                    <FaFacebook /> <span> Facebook </span>
                  </li>
                </Link>
                <Link to={routes.home}>
                  <li>
                    <FaTwitter /> <span> Twitter </span>
                  </li>{" "}
                </Link>
                <Link to={routes.home}>
                  <li>
                    <FaLinkedin /> <span> LinkedIn </span>
                  </li>{" "}
                </Link>
                <Link to={routes.home}>
                  <li>
                    <FaWhatsapp /> <span> Whatsapp </span>
                  </li>{" "}
                </Link>
              </ul>
            </div>
          </div>
        </div>
        <div className="copy-right">
          <div>
            &copy; Copyright
            <strong>
              {" "}
              <span> Happy Life & Peace </span>
            </strong>{" "}
            {new Date().getFullYear()}. Tous droits réservés
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultFooter;

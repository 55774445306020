import React from "react";
import {
  FaFacebookF,
  FaLinkedin,
  FaShareAlt,
  FaTumblr,
  FaTwitter,
  FaVimeoV,
} from "react-icons/fa";

const Team = () => {
  return (
    <div className="team-container">
      <div className="team-hero-container">
        <div className="b-container">
          <div className="team-hero">
            <h2 data-aos="fade-up">Notre Équipe</h2>
          </div>
        </div>
      </div>
     {/*  <div className="team-member-container">
        <div className="b-container">
          <h3>Notre Conseil d'Administration</h3>
          <div className="team-member-wraper">
            <div className="team-member" data-aos="zoom-in-left">
              <div className="team-member-img">
                <img src="/assets/images/img.jpeg" alt="" />
                <ul>
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <FaTwitter />
                  </li>
                  <li>
                    <FaLinkedin />
                  </li>
                </ul>
              </div>
              <div className="team-member-desc">
                <div className="team-member-name">Serge MAHUGNON</div>{" "}
                <div className="team-member-title text-muted">
                  Consultant SEO
                </div>
              </div>
            </div>
            <div className="team-member" data-aos="zoom-in-right">
              <div className="team-member-img">
                <img src="/assets/images/img1.jpeg" alt="" />
                <ul>
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <FaTwitter />
                  </li>
                  <li>
                    <FaLinkedin />
                  </li>
                </ul>
              </div>
              <div className="team-member-desc">
                <div className="team-member-name">Marielle AHOUANYE</div>{" "}
                <div className="team-member-title text-muted">
                  Spécialiste Marketing
                </div>
              </div>
            </div>
            <div className="team-member" data-aos="zoom-in-down">
              <div className="team-member-img">
                <img src="/assets/images/img.jpeg" alt="" />
                <ul>
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <FaTwitter />
                  </li>
                  <li>
                    <FaLinkedin />
                  </li>
                </ul>
              </div>
              <div className="team-member-desc">
                <div className="team-member-name">Serge MAHUGNON</div>{" "}
                <div className="team-member-title text-muted">
                  Consultant SEO
                </div>
              </div>
            </div>
            <div className="team-member" data-aos="zoom-in-up">
              <div className="team-member-img">
                <img src="/assets/images/img1.jpeg" alt="" />
                <ul>
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <FaTwitter />
                  </li>
                  <li>
                    <FaLinkedin />
                  </li>
                </ul>
              </div>
              <div className="team-member-desc">
                <div className="team-member-name">Marielle AHOUANYE</div>{" "}
                <div className="team-member-title text-muted">
                  Spécialiste Marketing
                </div>
              </div>
            </div>
            <div className="team-member" data-aos="zoom-in-left">
              <div className="team-member-img">
                <img src="/assets/images/img.jpeg" alt="" />
                <ul>
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <FaTwitter />
                  </li>
                  <li>
                    <FaLinkedin />
                  </li>
                </ul>
              </div>
              <div className="team-member-desc">
                <div className="team-member-name">Serge MAHUGNON</div>{" "}
                <div className="team-member-title text-muted">
                  Consultant SEO
                </div>
              </div>
            </div>
            <div className="team-member" data-aos="zoom-in-right">
              <div className="team-member-img">
                <img src="/assets/images/img1.jpeg" alt="" />
                <ul>
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <FaTwitter />
                  </li>
                  <li>
                    <FaLinkedin />
                  </li>
                </ul>
              </div>
              <div className="team-member-desc">
                <div className="team-member-name">Marielle AHOUANYE</div>{" "}
                <div className="team-member-title text-muted">
                  Spécialiste Marketing
                </div>
              </div>
            </div>
            <div className="team-member" data-aos="zoom-in-down">
              <div className="team-member-img">
                <img src="/assets/images/img.jpeg" alt="" />
                <ul>
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <FaTwitter />
                  </li>
                  <li>
                    <FaLinkedin />
                  </li>
                </ul>
              </div>
              <div className="team-member-desc">
                <div className="team-member-name">Serge MAHUGNON</div>{" "}
                <div className="team-member-title text-muted">
                  Consultant SEO
                </div>
              </div>
            </div>
            <div className="team-member" data-aos="zoom-in-up">
              <div className="team-member-img">
                <img src="/assets/images/img1.jpeg" alt="" />
                <ul>
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <FaTwitter />
                  </li>
                  <li>
                    <FaLinkedin />
                  </li>
                </ul>
              </div>
              <div className="team-member-desc">
                <div className="team-member-name">Marielle AHOUANYE</div>{" "}
                <div className="team-member-title text-muted">
                  Spécialiste Marketing
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

     {/*  <div className="team-member-container">
        <div className="b-container">
          <h3>Nos Responsables Commissions</h3>
          <div className="team-member-wraper">
            <div className="team-member" data-aos="flip-left">
              <div className="team-member-img">
                <img src="/assets/images/img.jpeg" alt="" />
                <ul>
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <FaTwitter />
                  </li>
                  <li>
                    <FaLinkedin />
                  </li>
                </ul>
              </div>
              <div className="team-member-desc">
                <div className="team-member-name">Serge MAHUGNON</div>{" "}
                <div className="team-member-title text-muted">
                  Consultant SEO
                </div>
              </div>
            </div>
            <div className="team-member" data-aos="flip-right">
              <div className="team-member-img">
                <img src="/assets/images/img1.jpeg" alt="" />
                <ul>
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <FaTwitter />
                  </li>
                  <li>
                    <FaLinkedin />
                  </li>
                </ul>
              </div>
              <div className="team-member-desc">
                <div className="team-member-name">Marielle AHOUANYE</div>{" "}
                <div className="team-member-title text-muted">
                  Spécialiste Marketing
                </div>
              </div>
            </div>
            <div className="team-member" data-aos="flip-down">
              <div className="team-member-img">
                <img src="/assets/images/img.jpeg" alt="" />
                <ul>
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <FaTwitter />
                  </li>
                  <li>
                    <FaLinkedin />
                  </li>
                </ul>
              </div>
              <div className="team-member-desc">
                <div className="team-member-name">Serge MAHUGNON</div>{" "}
                <div className="team-member-title text-muted">
                  Consultant SEO
                </div>
              </div>
            </div>
            <div className="team-member" data-aos="flip-up">
              <div className="team-member-img">
                <img src="/assets/images/img1.jpeg" alt="" />
                <ul>
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <FaTwitter />
                  </li>
                  <li>
                    <FaLinkedin />
                  </li>
                </ul>
              </div>
              <div className="team-member-desc">
                <div className="team-member-name">Marielle AHOUANYE</div>{" "}
                <div className="team-member-title text-muted">
                  Spécialiste Marketing
                </div>
              </div>
            </div>
            <div className="team-member" data-aos="flip-left">
              <div className="team-member-img">
                <img src="/assets/images/img.jpeg" alt="" />
                <ul>
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <FaTwitter />
                  </li>
                  <li>
                    <FaLinkedin />
                  </li>
                </ul>
              </div>
              <div className="team-member-desc">
                <div className="team-member-name">Serge MAHUGNON</div>{" "}
                <div className="team-member-title text-muted">
                  Consultant SEO
                </div>
              </div>
            </div>
            <div className="team-member" data-aos="flip-right">
              <div className="team-member-img">
                <img src="/assets/images/img1.jpeg" alt="" />
                <ul>
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <FaTwitter />
                  </li>
                  <li>
                    <FaLinkedin />
                  </li>
                </ul>
              </div>
              <div className="team-member-desc">
                <div className="team-member-name">Marielle AHOUANYE</div>{" "}
                <div className="team-member-title text-muted">
                  Spécialiste Marketing
                </div>
              </div>
            </div>
            <div className="team-member" data-aos="flip-down">
              <div className="team-member-img">
                <img src="/assets/images/img.jpeg" alt="" />
                <ul>
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <FaTwitter />
                  </li>
                  <li>
                    <FaLinkedin />
                  </li>
                </ul>
              </div>
              <div className="team-member-desc">
                <div className="team-member-name">Serge MAHUGNON</div>{" "}
                <div className="team-member-title text-muted">
                  Consultant SEO
                </div>
              </div>
            </div>
            <div className="team-member" data-aos="flip-up">
              <div className="team-member-img">
                <img src="/assets/images/img1.jpeg" alt="" />
                <ul>
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <FaTwitter />
                  </li>
                  <li>
                    <FaLinkedin />
                  </li>
                </ul>
              </div>
              <div className="team-member-desc">
                <div className="team-member-name">Marielle AHOUANYE</div>{" "}
                <div className="team-member-title text-muted">
                  Spécialiste Marketing
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="team-member-container">
        <div className="b-container">
          <h3>Quelques membres bénévoles</h3>
          <div className="team-member-wraper">
            <div className="team-member" data-aos="fade-left">
              <div className="team-member-img">
                <img src="/assets/images/ulrich.jpg" alt="" />
                <ul>
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <FaTwitter />
                  </li>
                  <li>
                    <FaLinkedin />
                  </li>
                </ul>
              </div>
              <div className="team-member-desc">
                <div className="team-member-name">Ulrich TIOMON BOCO</div>{" "}
                <div className="team-member-title text-muted">
                  Président Fondateur
                </div>
              </div>
            </div>
            <div className="team-member" data-aos="fade-down">
              <div className="team-member-img">
                <img src="/assets/images/william.jpg" alt="" />
                <ul>
                  <li>
                    <FaFacebookF />
                  </li>
                  <li>
                    <FaTwitter />
                  </li>
                  <li>
                    <FaLinkedin />
                  </li>
                </ul>
              </div>
              <div className="team-member-desc">
                <div className="team-member-name">William OFFIN</div>{" "}
                <div className="team-member-title text-muted">
                  Membre actif 
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;

import React from "react";
import { FaCheck, FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { routes } from "../../routes";

const About = () => {
  return (
    <div className="about-container">
      <div className="about-hero-container">
        <div className="b-container">
          <div className="about-hero">
            <h2>A propos de nous</h2>
          </div>
        </div>
      </div>
      <div className="about-us-container">
        <div className="b-container">
          <div className="about-us-wraper">
            <div className="about-us">
              <h3> Qui sommes-nous ?</h3>
              <div>
                Happy Life and Peace est une organisation non gouvernementale
                ONG créée le (18 janvier 2017) et reconnue officiellement le 05
                janvier 2018 au numéro 2018/003/DEP-ATL/SG/SAG-ASSOC. Elle est
                basée dans la commune de Abomey-Calavi, département de
                l’Atlantique, République du Bénin (Afrique de l’Ouest).
              </div>
              <p>
                Nous intervenonons sur toute l'étendue du territoire nationale.
                Depuis sa création, l'ONG a travaillé sans relâche pour
                améliorer la vie des populations les plus vulnérables et les
                plus défavorisées, en mettant l'accent sur l'utilisation des
                technologies innovantes pour atteindre ses objectifs.
              </p>
            </div>
            <div className="about-us-image">
              <img
                src="/assets/images/whoami.jpeg"
                alt=""
                style={{
                  maxHeight: "400px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="our-value-container">
        <div className="b-container">
          <div className="our-value-wraper">
            <div className="our-value">
              <span>1</span>
              <div>
                <FaCheck />
              </div>
              <div>Innovation et Créativité</div>
            </div>
            <div className="our-value">
              <span>2</span>
              <div>
                {" "}
                <FaCheck />
              </div>
              <div>Respect de l'environnement</div>
            </div>
            <div className="our-value">
              <span>3</span>
              <div>
                {" "}
                <FaCheck />
              </div>
              <div>Responsabilité et transparence</div>
            </div>
            <div className="our-value">
              <span>4</span>
              <div>
                <FaCheck />
              </div>
              <div>Empathie et solidarité</div>
            </div>
            {/*  <div className="our-value">
              <div>
                <FaCheck />
              </div>
              <div>Collaboration et Partenariat</div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="our-vision-container">
        <div className="b-container">
          <div className="our-vision-wraper">
            <div className="our-vision-image">
              <img src="/assets/images/our-vision.jpeg" alt="" />
            </div>
            <div className="our-vision">
              <h3> Notre vision</h3>
              <div>
                Happy Life and Peace oeuvre activement pour la promotion des
                TIC, le social, l'environnement, l'autonomisation des jeunes et
                des femmes tout en ayant une approche globale et
                multidisciplinaire.
              </div>
              <p>
                Notre vision est de contribuer à un monde plus juste et plus
                durable en travaillant avec les communautés locales, les
                partenaires gouvernementaux, les organisations non
                gouvernementales et les entreprises pour créer des changements
                significatifs et durables dans les domaines qui comptent le
                plus. Être une institution de référence pour la promotion des
                des TIC, le social, l'autonomisation des jeunes et des femmes
                des jeune et de la femme puis la protection de l'environnement.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="our-goals-container">
        <div className="b-container">
          <h3 className="our-goals-head">Nos Objectifs</h3>
          <div className="our-goals-wraper">
            <div className="our-goals">
              <div className="our-goals-title">TICs</div>
              <div className="our-goals-description">
                Promouvoir les Technologies de l'Information et des
                Télécommunications (TIC). Promouvoir l'économie numérique et
                faciliter leur accès aux jeunes et aux personnes privés de
                villes et de campagnes.
              </div>
            </div>
            <div className="our-goals">
              <div className="our-goals-title">Social</div>
              <div className="our-goals-description">
                Soutenir les enfants orphelins, les femmes et les personnes
                handicapées et fournir les accompagner, notamment dans les
                domaines de l'éducation et de la santé.
              </div>
            </div>
            <div className="our-goals">
              <div className="our-goals-title">Environnement</div>
              <div className="our-goals-description">
                Contribuer à l'assainissement et à la protection de
                l'environnement ainsi qu'à la promotion de l'agro-industrie;
              </div>
            </div>
            <div className="our-goals">
              <div className="our-goals-title">
                Autonomisation des jeunes et des femmes
              </div>
              <div className="our-goals-description">
                Renforcer les capacités des jeunes et des femmes en
                entrepreneuriat en vue à leur autonomisation et contribuer au
                développement des communautés à travers des activités sportives
                culturelles et récréatives.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-mission-container">
        <div className="b-container">
          <div className="our-mission-wraper">
            <div className="our-mission">
              <h3> Notre mission</h3>
              <div>
                Happy Life and Peace est engagée à travailler sans relâche pour
                promouvoir des pratiques durables dans ses domaines
                d'interventions.{" "}
                {/* des TICs, du
                social, de l'environnement et de l'autonomisation des jeunes et
                des femmes. */}
              </div>
              <p>
                Notre mission est de promouvoir des pratiques durables dans ces
                différents domaines, en développant des solutions innovantes et
                en sensibilisant les communautés sur l'importance de la
                préservation de l'environnement et de l'autonomisation des
                jeunes et des femmes. Nous croyons que la technologie peut être
                un puissant levier pour le changement positif, en permettant aux
                communautés de mieux comprendre les enjeux environnementaux et
                sociaux, et de trouver des solutions innovantes pour y faire
                face. Nous sommes convaincus que notre travail peut contribuer à
                un avenir plus juste et plus durable pour tous, et nous nous
                engageons à continuer à faire progresser cette mission
                importante.
              </p>
            </div>
            <div className="our-mission-image">
              <img src="/assets/images/mission1.jpeg" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="our-history-container">
        <div className="b-container">
          <div className="our-history-wraper">
            <div className="our-history-image">
              <img src="/assets/images/tic1.jpeg" alt="" />
            </div>
            <div className="our-history">
              <h3> Notre histoire</h3>
              <div>
                Depuis sa création, l'ONG a travaillé sans relâche pour
                améliorer la vie des populations les plus vulnérables et les
                plus défavorisées, en mettant l'accent sur l'utilisation des
                technologies innovantes pour atteindre ses objectifs. Nous
                sommes intervenus dans plusieurs domaines tels que :
                <ul>
                  <li>
                    <FaCheckCircle />
                    <span>
                      le TIC à travers notre projet de formation gratuite en
                      informatique de base aux jeunes des villes et contrées
                      reculées de notre pays
                    </span>
                  </li>
                  <li>
                    <FaCheckCircle />
                    <span>le social à travers des dons aux orphelins</span>
                  </li>
                  <li>
                    <FaCheckCircle />
                    <span>
                      {" "}
                      l'environnement à travers la participation aux différentes
                      actions/activités liées à la protection de l'environnement
                    </span>
                  </li>
                  <li>
                    <FaCheckCircle />
                    <span>
                      la santé et l'autonomisation des femmes à travers des
                      ateliers de formation en accessoires de mode textile,
                      make-up et attache foulard et l'autonomisation des jeunes
                      à travers des ateliers de formation en sérigraphie et
                      chantournage
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-team-container">
        <div className="b-container">
          <div className="our-team-wraper">
            <div className="our-team">
              <h3> Notre équipe</h3>
              <div>
                L'équipe de l'ONG Happy Life and Peace est composée de jeunes
                professionnels hautement qualifiés et engagés, qui sont
                passionnés par la promotion de la durabilité dans les domaines
                des TICs, du social, de l'environnement et de l'autonomisation
                des jeunes et des femmes.
              </div>
              <p>
                Chacun de nos membres est sélectionné pour ses compétences
                techniques et sa passion pour notre mission, et chacun apporte
                une perspective unique et précieuse à notre travail. Nos équipes
                de projet sont composées de professionnels qualifiés dans leur
                domaine respectif, tels que les TICs, l'environnement, le
                développement social et l'autonomisation des jeunes et des
                femmes.
              </p>
            </div>
            <div className="our-team-image">
              <img src="/assets/images/team.jpeg" alt="" />
            </div>
          </div>
          <div className="our-team-wraper">
            <div className="our-team-image">
              <img src="/assets/images/team2.jpeg" alt="" />
            </div>
            <div className="our-team">
              <div>
                Notre équipe est dirigée par un conseil d'administration composé
                de personnes expérimentées dans le secteur, qui apportent des
                années d'expérience et de savoir-faire pour guider notre
                organisation dans l'accomplissement de notre mission tout en
                veillant à ce que tout soit mis en place de manière efficace et
                efficiente.
              </div>
              <p>
                Nous sommes également soutenus par une équipe de bénévoles
                dévoués, qui contribuent à notre mission en apportant leurs
                compétences et leur temps pour soutenir nos programmes et
                projets. Nous sommes fiers de compter sur leur soutien et leur
                engagement, qui sont essentiels pour notre organisation et nous
                sommes fiers de travailler avec une équipe aussi exceptionnelle.
              </p>
              <div className="d-flex">
                <Link to={routes.team}>
                  <div className="get-started-btn">Voir tous les membres</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="partner-section-container">
        <div className="b-container">
          <div className="partner-section">
            <div className="partner">
              <img
                src="/assets/images/pds-neutre.png"
                alt="association_parlons_droit_et_societe"
              />
            </div>
            <div className="partner">
              <img
                src="/assets/images/aa-consulting-neutre.png"
                alt="aa_consulting"
              />
            </div>
            <div className="partner">
              <img
                src="/assets/images/prosperity-neutre.png"
                alt="ong_peace_and_prosperity"
              />
            </div>
            <div className="partner">
              <img src="/assets/images/jci-neutre.png" alt="jci" />
            </div>
            <div className="partner">
              <img
                src="/assets/images/pds-neutre.png"
                alt="association_parlons_droit_et_societe"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="our-partner-container">
        <div className="b-container">
          <div className="our-partner-wraper">
            <div className="our-partner">
              <h3> Nos partenaires et donnateurs</h3>
              <div>
                Les partenaires de Happy Life sont des entreprises, des
                institutions gouvernementaux, des organisations ou associations
                soeurs et des personnes physiques souhaitant accompagnés l'ONG
                sur ses propojets ou activités
              </div>
              <p>
                Notre mission est de contribuer à un monde plus juste et plus
                durable en travaillant avec les communautés locales, les
                partenaires gouvernementaux, les organisations non
                gouvernementales et les entreprises pour créer des changements
                significatifs et durables dans les domaines qui comptent le
                plus.
              </p>
            </div>
            <div className="our-partner-image">
              <img src="/assets/images/partnership2.jpeg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

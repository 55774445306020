import React from "react";

const InputComponent = ({
  handleInput,
  placeholder,
  label,
  type,
  name,
  required,
  error,
  defaultValue,
  disabled,
  min,
  max,
  minLength,
  maxLength,
}) => {
  return (
    <div className="input-ctn">
      <label htmlFor={name}>{label}</label>
      <input
        type={type ? type : "text"}
        placeholder={placeholder ? placeholder : ""}
        id={name}
        defaultValue={defaultValue ? defaultValue : ""}
        onChange={handleInput}
        required={required ? required : false}
        disabled={disabled ? disabled : false}
        min={min ? min : ""}
        max={max ? max : ""}
        minLength={minLength ? minLength : ""}
        maxLength={maxLength ? maxLength : ""}
        style={error ? { borderColor: "red" } : {}}
      />
      <div className="errors">{error}</div>
    </div>
  );
};

export default InputComponent;

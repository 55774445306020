import IMG_8353 from "../assets/images/gallery/IMG_8353.jpg";
import IMG_8354 from "../assets/images/gallery/IMG_8354.jpg";
import IMG_8358 from "../assets/images/gallery/IMG_8358.jpg";
import IMG_8359 from "../assets/images/gallery/IMG_8359.jpg";
import IMG_8360 from "../assets/images/gallery/IMG_8360.jpg";
import IMG_8361 from "../assets/images/gallery/IMG_8361.jpg";
import IMG_8364 from "../assets/images/gallery/IMG_8364.jpg";
import IMG_8366 from "../assets/images/gallery/IMG_8366.jpg";
import IMG_8371 from "../assets/images/gallery/IMG_8371.jpg";
import IMG_8374 from "../assets/images/gallery/IMG_8374.jpg";
import IMG_8375 from "../assets/images/gallery/IMG_8375.jpg";
import IMG_8376 from "../assets/images/gallery/IMG_8376.jpg";
import IMG_8377 from "../assets/images/gallery/IMG_8377.jpg";
import IMG_8379 from "../assets/images/gallery/IMG_8379.jpg";
import IMG_8381 from "../assets/images/gallery/IMG_8381.jpg";
import IMG_8383 from "../assets/images/gallery/IMG_8383.jpg";
import IMG_8384 from "../assets/images/gallery/IMG_8384.jpg";
import IMG_8385 from "../assets/images/gallery/IMG_8385.jpg";
import IMG_8388 from "../assets/images/gallery/IMG_8388.jpg";
import IMG_8389 from "../assets/images/gallery/IMG_8389.jpg";

export const galleryData = [
  IMG_8353,
  IMG_8354,
  IMG_8358,
  IMG_8359,
  IMG_8360,
  IMG_8361,
  IMG_8364,
  IMG_8366,
  IMG_8371,
  IMG_8374,
  IMG_8375,
  IMG_8376,
  IMG_8377,
  IMG_8379,
  IMG_8381,
  IMG_8383,
  IMG_8384,
  IMG_8385,
  IMG_8388,
  IMG_8389,
];

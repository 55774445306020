import React from "react";
import { Spinner } from "react-bootstrap";

const MySpinner = ({ active }) => {
  return (
    <Spinner
      as="span"
      animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
      variant="primary"
      style={
        active
          ? { display: "inline-block" }
          : { display: "none" }
      }
    />
  );
};

export default MySpinner;

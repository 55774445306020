export const routes = {
  notFound: "*",
  home: "/",
  about: "/a-propos-de-nous",
  contact: "/nous-contacter",
  activities: "/nos-activités",
  tic: "/domaines/tic",
  environment: "/domaines/environnement",
  youth: "/domaines/autonomisation-des-jeunes-et-femmes",
  social: "/domaines/social",
  terms: "/conditions-d'utilisations",
  privacy: "/politique-de-confidentialité",
  partnership: "/devenir-partenaire",
  report: "/rapport-activités",
  login: "/auth/login",
  register: "/auth/register",
  reset: "/auth/password/reset",
  discussion: "/discussion",
  team:"/notre-equipe",
  help:"/aides",
  gallery:"/nos-images",
};
import React, { useState } from "react";
import {
  FaCommentDots,
  FaPhoneAlt,
  FaWhatsapp,
  FaFacebook,
  FaInstagram,
  FaHome,
  FaTwitter,
  FaLinkedin,
  FaBox,
  FaEnvelope,
} from "react-icons/fa";
import { validEmail } from "../../helpers/validation";
import { SubmitBtn } from "../../components";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubject = (e) => {
    setSubject(e.target.value);
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleForm = (e) => {
    e.preventDefault();
    setLoading(!isLoading);
    setErrors([]);
    let errs = [];
  };
  return (
    <div className="contact-container">
      <div className="contact-hero-container">
        <div className="b-container">
          <div className="contact-hero">
            <h2>Contactez-nous</h2>
          </div>
        </div>
      </div>
      <div className="text-section-container">
        <div className="b-container">
          <div className="text-section-wraper">
            <div className="text-section text-marked">
              Nous sommes impatients de vous entendre ! Si vous avez des
              questions, des commentaires ou des suggestions à nous faire part,
              n'hésitez pas à nous contacter en utilisant le formulaire
              ci-dessous. Nous nous nous efforçons de répondre à toutes les
              demandes dans les plus brefs délais. Que vous souhaitiez savoir ?
              Nous sommes là pour vous. Merci de prendre le temps de nous
              contacter et nous vous répondrons dès que possible.
            </div>
            <div className="text-section">
              Vous cherchez une opportunité de devenir partenaire et de
              collaborer avec nous ? Nous sommes ravis de vous présenter notre
              programme de partenariat exclusif ! En rejoignant notre réseau de
              partenaires, vous aurez accès à une multitude d'avantages,
              notamment une visibilité accrue pour votre entreprise, un accès
              privilégié nos ressources, ainsi que des opportunités de marketing
              et de promotion conjointes. Avec notre équipe dévouée de
              professionnels, nous sommes prêts à collaborer avec vous et à
              construire ensemble un partenariat gagnant-gagnant. Remplissez
              simplement le formulaire de contact ci-dessous pour en savoir plus
              sur notre programme de partenariat et pour nous parler de votre
              entreprise. Nous avons hâte de travailler avec vous !
            </div>
          </div>
        </div>
      </div>
      <div className="partner-section-container">
        <div className="b-container">
          <div className="partner-section">
            <div className="partner">
              <img
                src="/assets/images/pds-neutre.png"
                alt="association_parlons_droit_et_societe"
              />
            </div>
            <div className="partner">
              <img
                src="/assets/images/aa-consulting-neutre.png"
                alt="aa_consulting"
              />
            </div>
            <div className="partner">
              <img
                src="/assets/images/prosperity-neutre.png"
                alt="ong_peace_and_prosperity"
              />
            </div>
            <div className="partner">
              <img src="/assets/images/jci-neutre.png" alt="jci" />
            </div>
            <div className="partner">
              <img
                src="/assets/images/pds-neutre.png"
                alt="association_parlons_droit_et_societe"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="info-container">
        <div className="b-container">
          <div className="info-text-container"></div>
          <div className="info-map-container"></div>
        </div>
      </div>
      <div className="contact-section-container">
        <div className="b-container">
          <div className="contact-section-wraper">
            <div className="contact-section-info">
              <div className="p-text">
                Nous sommes situés à Godomey derrière l'échangeur Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Modi delectus, sit
                magni voluptatum optio fuga!
              </div>
              <ul className="contact-section-info-grid">
                <li>
                  <FaWhatsapp />
                  <span>WhatsApp</span>
                </li>
                <li>
                  <FaFacebook />
                  <span>Facebook</span>
                </li>
                <li>
                  <FaTwitter />
                  <span>Twitter</span>
                </li>
                <li>
                  <FaLinkedin />
                  <span>LinkedIn</span>
                </li>
                <li>
                  <FaInstagram />
                  <span>Instagram</span>
                </li>
                <li className="d-flex">
                  <FaPhoneAlt />
                  <span>+22965814144</span>
                </li>
                <li className="d-flex align-items-center">
                  <FaEnvelope />
                  <span>hlpong@gmail.com</span>
                </li>
                <li className="d-flex align-items-center">
                  <FaEnvelope />
                  <span>contact@hlp.org</span>
                </li>
              </ul>
            </div>
            <div className="contact-section">
              <h2>Contactez-nous</h2>
              <div className="contact-form">
                <form onSubmit={handleForm}>
                  <div className="input-ctn">
                    <label htmlFor="name">Nom</label>
                    <input type="text" id="name" />
                  </div>
                  <div className="input-ctn">
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" />
                  </div>
                  <div className="input-ctn">
                    <label htmlFor="subject">Sujet</label>
                    <input type="text" id="subject" />
                  </div>
                  <div className="input-ctn">
                    <label htmlFor="msg">Message</label>
                    <textarea id="msg" rows="5"></textarea>
                  </div>
                  <SubmitBtn title="Envoyer" isLoading={isLoading} />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React from 'react';

const PasswordReset = () => {
    return (
        <div>
            ok
        </div>
    );
};

export default PasswordReset;
import React from "react";
import { Outlet } from "react-router-dom";
import { AuthHeader, AuthFooter } from "../../components";

const AuthLayout = () => {
  return (
    <div className="auth-layout-container">
{/*       <header>
        <AuthHeader />
      </header> */}
      <main>
        <Outlet />
      </main>
      {/* <footer>
        <AuthFooter/>
      </footer> */}
    </div>
  );
};

export default AuthLayout;

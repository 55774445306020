import React, { useState } from "react";
import { InputComponent, PasswordComponent, SubmitBtn } from "../../components";
import { Link } from "react-router-dom";
import { routes } from "../../routes";
import { validEmail, validPassword } from "../../helpers/validation";
import AuthService from "../../services/AuthService";
import { ToastContainer, toast } from "react-toastify";

const Login = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    setLoading(true);
    setError("");
    e.preventDefault();

    if (!email || !password) {
      setError("Veuillez remplir tous les champs");
      setLoading(false);
    } else if (!validEmail(email)) {
      setError("Veuillez entrer un email correct");
      setLoading(false);
    } else {
      const data = {
        email: email,
        password: password,
      };
      AuthService.login(data)
        .then((res) => {
          if (res.data.error) {
            toast.error("Email ou mot de passe incorrect");
          } else {
            sessionStorage.setItem("data", JSON.stringify(res.data));
            window.location.replace(routes.dashboard);
          }
          setLoading(false);
        })
        .catch((err) => {
          if (err.satus == 400) {
            toast.error("Email ou mot de passe incorrect");
          } else {
            toast.error("Erreur lors de la connexion");
          }
          setLoading(false);
        });
    }
  };

  return (
    <div className="login-container">
      {/* <div className="login-hero-container">
        <div className="img">
          <img src="/assets/images/levelop-logo-white.svg" alt="" />
        </div>
        <h3>Bon retour !</h3>
        <h6>
          Faites transporter vos marchandises en toute sécurité par des experts.
        </h6>
      </div> */}
      <ToastContainer />
      <div className="login-form-container">
        <div className="login-form-wraper">
          <h5>Content de vous revoir</h5>
          <div className="text-center">
            Entrez vos identifiants pour vous connecter{" "}
          </div>
          <div className="login-form">
            <form onSubmit={handleSubmit} className="login-form-content">
              {error ? <div className="errors">{error}</div> : ""}
              <InputComponent
                name="email"
                type="email"
                label="Email"
                placeholder="test@email.com"
                handleInput={handleEmail}
                required={true}
              />
              <PasswordComponent handlePassword={handlePassword}/>

              <div className="remember-forgot">
                <div className="remember-container">
                  {" "}
                  <input type="checkbox" id="keep" />
                  <label htmlFor="keep">Se souvenir de moi</label>
                </div>
                {/*  <div className="forgot">
                  <Link to={routes.reset}>Mot de passe oublié ?</Link>
                </div> */}
              </div>
              <SubmitBtn isLoading={isLoading} title="Se connecter" />
              <p className="create-account">
                <Link to={routes.reset}>Mot de passe oublié ?</Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React from "react";

const Terms = () => {
  return (
    <div className="terms-container">
      <div className="terms-hero-container">
        <div className="b-container">
        <div className="terms-hero">
          <h2> Conditions Générales d'Utilisation</h2>
        </div>
        </div>
      </div>
      <div className="terms-term-container">
        <div className="b-container">
          <p>
            Veuillez lire attentivement les présentes Conditions Générales
            d'Utilisation ("CGU") avant d'utiliser le site web de l'ONG Happy
            Life and Peace. En utilisant notre site web, vous acceptez de vous
            conformer à ces CGU et d'être lié par elles. Si vous n'acceptez pas
            ces CGU, veuillez ne pas utiliser notre site.
          </p>
          <div className="terms-term-wraper">
            <div className="terms-term-title">Utilisation du site</div>
            <div className="terms-term-desc">
              Vous acceptez d'utiliser notre site web conformément à toutes les
              lois applicables et aux présentes CGU. Vous ne pouvez pas utiliser
              le site d'une manière qui pourrait causer un préjudice à l'ONG
              Happy Life and Peace, à d'autres utilisateurs ou à des tiers.
            </div>
          </div>
        </div>
      </div>
      <div className="terms-term-container">
        <div className="b-container">
          <div className="terms-term-wraper">
            <div className="terms-term-title">Contenu du site</div>
            <div className="terms-term-desc">
              Le contenu de notre site web est fourni à titre informatif
              seulement. Nous nous efforçons de maintenir le contenu à jour et
              exact, mais nous ne garantissons pas son exhaustivité, son
              exactitude ou sa pertinence. Vous utilisez le contenu de notre
              site web à vos propres risques.
            </div>
          </div>
        </div>
      </div>
      <div className="terms-term-container">
        <div className="b-container">
          <div className="terms-term-wraper">
            <div className="terms-term-title">Propriété intellectuelle</div>
            <div className="terms-term-desc">
              Tous les contenus, les marques, les logos et les informations
              présents sur notre site web sont la propriété exclusive de l' ONG
              Happy Life and Peace, sauf indication contraire. Vous n'êtes pas
              autorisé à copier, reproduire, modifier, distribuer ou utiliser de
              quelque manière que ce soit le contenu de notre site web sans
              notre consentement écrit préalable.
            </div>
          </div>
        </div>
      </div>

      <div className="terms-term-container">
        <div className="b-container">
          <div className="terms-term-wraper">
            <div className="terms-term-title">Liens vers des sites tiers</div>
            <div className="terms-term-desc">
              Notre site web peut contenir des liens vers des sites web tiers.
              Nous n'avons aucun contrôle sur le contenu de ces sites et nous
              déclinons toute responsabilité quant à leur exactitude, leur
              légalité ou leur fiabilité. L'accès à ces sites tiers se fait à
              vos propres risques.
            </div>
          </div>
        </div>
      </div>
      <div className="terms-term-container">
        <div className="b-container">
          <div className="terms-term-wraper">
            <div className="terms-term-title">
              Protection des données personnelles
            </div>
            <div className="terms-term-desc">
              Nous collectons, utilisons et protégeons vos données personnelles
              conformément à notre politique de confidentialité. En utilisant
              notre site web, vous consentez à la collecte et à l'utilisation de
              vos données personnelles conformément à notre politique de
              confidentialité.
            </div>
          </div>
        </div>
      </div>
      <div className="terms-term-container">
        <div className="b-container">
          <div className="terms-term-wraper">
            <div className="terms-term-title">Exclusion de responsabilité</div>
            <div className="terms-term-desc">
              Dans toute la mesure permise par la loi, l'ONG Happy Life and
              Peace décline toute responsabilité quant aux dommages directs,
              indirects, accessoires, consécutifs ou spéciaux découlant de
              l'utilisation de notre site web ou de l'incapacité à l'utiliser.
            </div>
          </div>
        </div>
      </div>
      <div className="terms-term-container">
        <div className="b-container">
          <div className="terms-term-wraper">
            <div className="terms-term-title">Modification des CGU</div>
            <div className="terms-term-desc">
              Nous nous réservons le droit de modifier ou de mettre à jour ces
              CGU à tout moment, sans préavis. Les modifications entreront en
              vigueur dès leur publication sur notre site web. Il est de votre
              responsabilité de vérifier régulièrement les CGU pour prendre
              connaissance des éventuelles modifications.
            </div>
          </div>
        </div>
      </div>
      <div className="terms-term-container">
        <div className="b-container">
          <div className="terms-term-wraper">
            <div className="terms-term-title">Contactez-nous</div>
            <div className="terms-term-desc">
              Si vous avez des questions, des commentaires ou des préoccupations
              concernant ces CGU, veuillez nous contacter à l'adresse suivante :
              contact@hlp.org
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;

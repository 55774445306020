import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const PasswordComponent = ({ handlePassword, error }) => {
  const [showPassword, setShowPassword] = useState("password");
  const handleShowPassword = (e) => {
    if (showPassword == "password") {
      setShowPassword("text");
    } else {
      setShowPassword("password");
    }
  };

  return (
    <div className="password-ctn">
      <label htmlFor="password">Mot de Passe</label>

      <div className="password">
        <input
          type={showPassword}
          id="password"
          onChange={handlePassword}
          required
        />
        {showPassword === "password" ? (
          <span onClick={handleShowPassword}>
            <FaEye />
          </span>
        ) : (
          <span onClick={handleShowPassword}>
            <FaEyeSlash />
          </span>
        )}
      </div>
      <div className="errors">{error}</div>
    </div>
  );
};

export default PasswordComponent;

import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { routes } from "../../routes";
import Slider from "react-slick";
import { galleryData } from "../../utils/data";

const Gallery = () => {
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    lazyLoad: true,
    fade: true,
    // slidesToShow: 4,
    //slidesToScroll: 4,
    //initialSlide: 0,
    /* autoplay: true,
    autoplaySpeed: 2000, */
    cssEase: "linear",
    slidesPerRow: 4,
    rows: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setDataToDisplay(galleryData);
  }, []);
  return (
    <div className="gallery-container">
      <div
        className="gallery-hero-container"
        style={{ backgroundImage: "url(/assets/images/social.jpeg)" }}
      >
        <div className="b-container">
          <div className="gallery-hero">
            <h2>Gallerie</h2>
          </div>
        </div>
      </div>
      <div className="gallery-filter-container">
        <div className="b-container">
          {/* <div className="gallery-filter-wraper">
            <div className="gallery-filter">
              <Form.Select aria-label="Default select example">
                <option value="all">Toutes les activités</option>
                <option value="1">PZAM3</option>
                <option value="2">Ecojogging</option>
                <option value="3">World Clean up day</option>
              </Form.Select>
            </div>
            <div className="gallery-filter">
              <Form.Select aria-label="Default select example">
                <option value="all">Toutes les années</option>
                <option value="1">2014</option>
                <option value="2">2015</option>
                <option value="3">2016</option>
              </Form.Select>
            </div>
          </div> */}
        </div>
      </div>
      <div className="gallery-section-container">
        <div className="b-container">
          <div className="gallery-section-wraper">
            <div className="gallery-section">
              <div className="slider-container">
                <Slider {...settings}>
                  {dataToDisplay.map((imageSrc, index) => (
                    <Link to={imageSrc} target="_blank" className="slide-image">
                      <img src={imageSrc} alt="" />
                    </Link>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;

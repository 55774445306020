export const validEmail = (value) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );
};

export const phone = (value) => {
  return;
  /^([0]{1}|\+?[234]{3})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7})$/g.test(value);
};

export const validPassword = (value) => {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&-+=()!]).{8,24}$/.test(
    value
  ); 
  /* return /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(value); */
};

export const hasNumber = (value) => {
  return /^[0-9]+$/.test(value);
};

export const hasUpperCase = (value) => {
  return /^([A-Z]+)$/.test(value);
};
export const hasLowerCase = (value) => {
  return /^[a-z]+$/.test(value);
};

export const hasSpecial = (value) => {
  return /^[@#$%^&-+=()]+$/.test(value);
};

export const onlyLetters = (value) => {
  return /^[a-zA-Z]+$/.test(value);
};

export const onlyLettersWithSpace = (value) => {
  return /^[a-zA-Z ]*$/.test(value);
};

export const schoolYear = (value) => {
  return /^(?=.*[0-9])(?=.*[-]).{9}$/.test(
    value
  ); 
};

import "./styles/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "./ScrollToTop";
import { RequireAuth } from "./gates";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthLayout, DashboardLayout, DefaultLayout } from "./layouts";
import {
  Login,
  PasswordReset,
  NotFound,
  Dashboard,
  Note,
  Class,
  Student,
  Exam,
  Discussion,
  Home,
  About,
  Contact,
  Report,
  Activity,
  Terms,
  Privacy,
  Partnership,
  Tic,
  Environment,
  Youth,
  Social,
  Gallery,
} from "./pages";
import { routes } from "./routes";
import { useEffect, useLayoutEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import Team from "./pages/frontOffice/Team";
import Aos from "aos";

function App() {
  useEffect(() => {
    Aos.init({

      duration: 700, // values from 0 to 3000, with step 50ms
      easing: 'ease-in',
    });
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route element={<AuthLayout />}>
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.reset} element={<PasswordReset />} />
        </Route>
        <Route element={<DefaultLayout />}>
          <Route path={routes.listCommand} element={<listCommand />} />
          <Route path={routes.detailCommand} element={<detailCommand />} />
          <Route path={routes.home} element={<Home />} />
          <Route path={routes.about} element={<About />} />
          <Route path={routes.contact} element={<Contact />} />
{/*           <Route path={routes.report} element={<Report />} />
 */}          <Route path={routes.activities} element={<Activity />} />
          <Route path={routes.tic} element={<Tic />} />
          <Route path={routes.environment} element={<Environment />} />
          <Route path={routes.social} element={<Social />} />
          <Route path={routes.youth} element={<Youth />} />
          <Route path={routes.terms} element={<Terms />} />
          <Route path={routes.privacy} element={<Privacy />} />
          <Route path={routes.partnership} element={<Partnership />} />
          <Route path={routes.team} element={<Team />} />
          <Route path={routes.gallery} element={<Gallery />} />
        </Route>
        {/* <Route element={<DashboardLayout />}>
          <Route path={routes.dashboard} element={<Dashboard />} />
          <Route path={routes.teacher} element={<Teacher />} />
          <Route path={routes.subject} element={<Subject />} />
          <Route path={routes.note} element={<Note />} />
          <Route path={routes.class} element={<Class />} />
          <Route path={routes.student} element={<Student />} />
          <Route path={routes.exam} element={<Exam />} />
          <Route path={routes.discussion} element={<Discussion />} />
        </Route>
                 <Route element={<AuthLayout />}>
          <Route element={<DeniedLogin />}>
            <Route path={routes.home} element={<Login />} />
            <Route path={routes.reset} element={<PasswordReset />} />
          </Route>
        </Route> */}
        {/*         <Route element={<RequireAuth allowedRoles={[0, 1]} />}>
          <Route element={<DashboardLayout />}>
            <Route path={routes.dashboard} element={<Dashboard />} />
          </Route>
        </Route> */}
      </Routes>
    </Router>
  );
}

export default App;

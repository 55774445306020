import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { routes } from "../../routes";
import { Link, NavLink } from "react-router-dom";

const SideNav = () => {
  return (
    <div className="activity-side-container">
      <div className="activity-side-link-container">
        <NavLink
          to={routes.tic}
          className={(nav) => (nav.isActive ? "activity-side-link-active" : "")}
        >
          <div className="activity-side-link">
            TIC{" "}
            <span>
              <FaArrowRight />
            </span>
          </div>
        </NavLink>
        <NavLink
          to={routes.social}
          className={(nav) => (nav.isActive ? "activity-side-link-active" : "")}
        >
          <div className="activity-side-link">
            Social
            <span>
              <FaArrowRight />
            </span>
          </div>
        </NavLink>
        <NavLink
          to={routes.environment}
          className={(nav) => (nav.isActive ? "activity-side-link-active" : "")}
        >
          {" "}
          <div className="activity-side-link">
            Environnement
            <span>
              <FaArrowRight />
            </span>
          </div>
        </NavLink>
        <NavLink
          to={routes.youth}
          className={(nav) => (nav.isActive ? "activity-side-link-active" : "")}
        >
          <div className="activity-side-link">
            Autonomisation
            <span>
              <FaArrowRight />
            </span>
          </div>
        </NavLink>
      </div>
      <div className="activity-side-contact-container">
        <h3>Happy Life & Peace</h3>
        <div className="activity-side-contact-text">
          Si vous vouliez faire un don, nous rejoindre ou vous aviez des
          préoccupations à nous soumetre n'hesitez pasà nous contacter.
        </div>
        <div className="activity-side-contact-btn m-auto mt-3">
          {" "}
          <Link to={routes.contact}>
            <div className="get-started-btn mx-2 text-center">
              Contactez nous
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
